.dataSheet{
	&__list{ margin: 0; padding: 0; list-style-type: none;
		li{ border-bottom: 1px solid #ccc; margin-bottom: 1rem; }
		&--solutions{ 
			p {font-size: 1rem; margin-bottom: .5em;}
			li {height: auto; margin-bottom: 8px;}
		 }
		p{ margin-bottom: .5em; }
	}
	&__svg{ width: 13.69px; height: auto; }
	& img:hover{ cursor: pointer; }
}


