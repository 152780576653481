@mixin futura-FT {
    font-family: $font-family-FT;
    font-weight: normal;
    font-style: normal;
}

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/
@mixin object-fit($fit: fill, $position: null){
    -o-object-fit: $fit;
       object-fit: $fit;
    @if $position {
        -o-object-position: $position;
           object-position: $position;
        font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    } @else {
        font-family: 'object-fit: #{$fit}';
    }
  }

  /*
  Diagonal bg
  */
  @mixin  diagonal-bg ($color : $primary, $dist-x : 66%){
    background-image: linear-gradient(100deg, $color, $color $dist-x, $white $dist-x, $white 100%);
  }
  @mixin trinagle ($color : $primary){
    width: 0; 
    height: 0; 
    border-top: 35px solid transparent;
    border-left: 30px solid $color; 
    border-bottom: 35px solid transparent;
    @include media-breakpoint-up(sm){
      border-top: 45px solid transparent;
      border-left: 50px solid $color; 
      border-bottom: 45px solid transparent;
    }  
  }