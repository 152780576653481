.crts {

  // &__sectionHeader {
  //   margin-bottom: 2.25rem;
  // }

  &__icon {
    &--footer { width: 1.6875rem; height: auto; }
  }


// }// ** crts END

// .bg-lightgray{ background-color: $light-gray; }
// .bg-split{
//     background:linear-gradient(
//     to bottom,
//     $white 0%,
//     $white 50%,
//     $light-gray 50%,
//     $light-gray 100%,
//     );
// }

// .grid-gallery{
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-template-rows: repeat(2, 200px);
//   grid-gap: 2rem;
//   @include media-breakpoint-up(md) {
//     grid-template-rows: repeat(2, 270px);
//   }
//   @include media-breakpoint-up(lg) {
//     grid-template-rows: repeat(2, 200px);
//   }
//   @include media-breakpoint-up(xl) {
//     grid-template-rows: repeat(2, 270px);
//   }
//   & img{
//     width: 100%;
//     height: 100%;
//     object-fit: cover;
//     object-position: center;
//     @include object-fit(cover, center); 
//   }
}

// .btn-wrap{
//   width: 100%;
//   transform: translateY(-100%);
//   position: relative;
// }

.btn{
  &--crts{
    font-size: 1.1rem;
    padding: 1em 2.25em;
    font-weight: 700;
    width: 100%;
    @include media-breakpoint-up(md) {
      font-size: 1.375rem;
      width: auto;
    }
  }
}


