.navbar {
    @include media-breakpoint-up(xl) { padding-top: 1.190625rem; }
}

.navbar-brand {
    
    @include media-breakpoint-up(xl) { margin-right: 50px; }
    svg { 
        height: 4rem;
        @include media-breakpoint-up(xl) { height: 92.972px; }
    }

}

.nav-link { 
    font-size: 1.375rem; padding: 1.5rem 1rem 0.5rem;

    @include media-breakpoint-up(xl) { font-size: 1.125rem; padding: 0.5rem 1rem; }
}

.navbar-expand-xl .navbar-nav .nav-link {
    @include media-breakpoint-up(xl) { padding-right: 1rem; padding-left: 1rem; border-bottom: 0; }
}

.navbar-nav {
    .nav-link { 
        color: $dark; border-bottom: 1px solid $gray; position: relative; 

        @include media-breakpoint-up(xl) { 
            &:before{
                content: "";
                position: absolute;
                width: calc(100% - 1rem);
                height: 4px;
                background-color: $primary;
                bottom: 0.3rem;
                left: .5rem;
                z-index: 1;
                opacity: 0;
                transition: all .15s linear;
            }
            
            &:hover { 
                color: $dark; 
                &:before{ opacity: 1; }
            }
        }
    }

    .active{ 
        > .nav-link { 
            color: $dark;
            @include media-breakpoint-up(xl) { &:before{ opacity: 1; } }
        }
    }
}

.navbar-collapse { @include media-breakpoint-down(lg) { min-height: 100vh; padding-top: 3rem; } }

#main-menu { 
    padding-top: 3rem;
    @include media-breakpoint-up(xl) { padding-top: 0;}
}

.lang-item {
    > a { 
        position: relative;
        width: fit-content;
        @include media-breakpoint-up(xl) { 
            &:before{
                content: "";
                position: absolute;
                width: 100%;
                height: 4px;
                background-color: $primary;
                bottom: -.3rem;
                left: 0;
                z-index: 1;
                opacity: 0;
                transition: all .15s linear;
            }
        }
    }
}
.current-lang{ 
    > a { @include media-breakpoint-up(xl) { &:before{ opacity: 1; } } }
 }

@import "navToggle";
@import "navDropdown";
