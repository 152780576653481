// ** Custom style svg
.svg{
	&--primary{ fill: $primary; fill-rule:evenodd; }
	&--dark{ fill: $dark; fill-rule:evenodd; }
	&--gray{ fill: $gray; fill-rule:evenodd; }
	&--white{ fill: $white; fill-rule:evenodd; }
	&--90{ transform: rotate(90deg); }
	&--180{ transform: rotate(180deg); }
	&--270{ transform: rotate(270deg); }
}

.crts__card-icon{
	width: 1em;
	height: 1em;
	margin-right: 1em;
}
