.carousel{
	&-inner{
		// @include media-breakpoint-up(md){
			margin-left: -$carousel-margin;
			padding-left: $carousel-margin;
			padding-right: $carousel-margin;
			padding-bottom: $carousel-margin*1.5;
			width: calc(100% + #{$carousel-margin}*2);
		// }
	}
	&-indicators{
		position: static;
		width: 100%;
		max-width: 490px;
		margin: 0;


		& li{
			width: 21px;
			height: 21px;
			border: 1px solid $primary;
			border-radius: 50%;
			background-color: $white;
			opacity: 1;

			&.active{
				background-color: $primary;
			}
		}
	}
	&-control{
		&-next{
			display: none;
			top: 38%;
			right: 0;
			left: 0;
			right: auto;
			bottom: auto;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background-color: $white;
			transform: translate(-110%,-50%);
			box-shadow: 0 3px 26px rgba(0,0,0,.15);
			opacity: 1;
			@include media-breakpoint-up(md){
				display: flex;
				transform: translate(-50%,-50%);
				width: 74px;
				height: 74px;
			}
		}
	}
}