.dropdown {
    a { display: flex; justify-content: space-between; }

    .mega-menu-back { justify-content: flex-start; }

    &-menu {
        &:not(.mega-menu-wrapper) {
            @include media-breakpoint-down(xl) {
                padding-left: 2.5em;
                border: 0;
                border-radius: 0;
            }
        }
        .not-ico-lang {
            .mega-menu-icon { display: none; }
        }
    }

    &.show{
        > .nav-link { 
            @include media-breakpoint-up(xl) { &:before{ opacity: 1; } }
        }
    }
}
