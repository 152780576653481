.crts {
    &__card {
        width: 100%;
        max-width: 490px;
        box-shadow: 0 3px 26px rgba(0,0,0,.15);
        // &-image {
        //     position: relative;
        //     width: 100%;
        //     padding-top: 56.94%;
        //     background: {
        //         repeat: no-repeat;
        //         size: cover;
        //         position: center;
        //     }


        //     &--vertical {
        //         padding-top: 100%;

        //         @include media-breakpoint-up(lg) {
        //             width: 50%;
        //             padding-top: 59.56%;
        //         }
        //     }

        //     &--overlay {
        //         position: absolute;
        //         bottom: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 0;
        //         background-color: $primary;
        //         mix-blend-mode: multiply;
        //         opacity: 0;
        //         visibility: hidden;
        //         transition: all 0.4s ease;
        //     }

        //     &--text {
        //         display: flex;
        //         justify-content: center;
        //         align-items: center;
        //         position: absolute;
        //         bottom: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 0;
        //         font-size: 1.2rem;
        //         font-weight: 700;
        //         letter-spacing: 1px;
        //         color: rgba(255, 255, 255, 0);
        //         opacity: 0;
        //         visibility: hidden;
        //         transition: all 0.3s ease .1s;
        //     }

        //     &:hover {
        //         .crts__card-image--overlay {
        //             opacity: 1;
        //             visibility: visible;
        //             height: 100%;
        //         }

        //         .crts__card-image--text {
        //             color: $white;
        //             opacity: 1;
        //             visibility: visible;
        //             height: 100%;
        //         }
        //     }
        // }

        &-body { padding: 1rem 0 0;

            @include media-breakpoint-up(lg) { padding: 20px; }

            // &--vertical {
            //     padding: 2rem;
            //     @include media-breakpoint-up(lg) {
            //         width: 50%;
            //         padding: 1.5rem;
            //         display: flex;
            //         flex-direction: column;
            //         justify-content: space-between;
            //         align-items: flex-start;
            //     }
            //     @include media-breakpoint-up(xl) { padding: 2.5rem; }
            // }
        }

        &-title {
            font-size: 1.25rem;
            font-weight: 700;
            line-height: 1.45em;
            margin: 0 0 7px 0;
            text-transform: uppercase;
            @include media-breakpoint-up(lg) { font-size: 1.4rem; margin: 0 0 21px 0; }

            // &--vertical {
            //     flex-grow: 2;
            //     font-size: 1.125rem;
            //     line-height: 1.36em;
            //     margin: 1.22em 0;

            //     // @include media-breakpoint-up(md) { font-size: 1.375rem; }

            //     // @include media-breakpoint-up(lg) { font-size: 1.15rem; margin: 1.22em 0 0 0; }

            //     @include media-breakpoint-up(xl) { font-size: 1.375rem; }
            // }

        }

        &-text { font-size: 0.9375rem; line-height: 24px; }

        &-icon { width: 13.69px; margin-right: 0.8em; }

        &-meta {
            @extend .p--metaTitle;
            margin: 0;
            text-transform: initial;

            &--dark { color: $dark; }
        }
    }
}
.box-shadow{box-shadow: 5px 5px 12px rgba(0,0,0,.2);}
